import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.alert.show = false;
        this.bottomRow = 'default';
        this.selectedCharge = 'overnight';
      }
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: 'PROCESSING TRANSACTION',
        submessage: 'Please do not close this window.'
      },
      selectedCharge: 'overnight',
      chargeOptions: [{
        value: 'overnight',
        text: 'Fedex Overnight - $50'
      }],
      chargeValues: {
        overnight: 50
      },
      bottomRow: 'default',
      showMessage: true
    };
  },
  methods: {
    async applyOvernightShipping() {
      try {
        this.alert.message = 'PROCESSING TRANSACTION';
        this.alert.submessage = 'Please do not close this window.';
        this.bottomRow = 'processing';
        this.alert.show = true;
        const {
          data
        } = await PatientService.applyOvernightShipping(this.rowData.id);
        if (data) {
          this.alert.message = 'TRANSACTION SUCCESS';
          this.alert.submessage = 'Patient was successfully charged.';
        }
      } catch (err) {
        this.alert.message = 'TRANSACTION FAILED';
        this.alert.submessage = `Patient was not successfully charged. \nStripe failed with error code: ${err.code}`;
        if (err.decline_code) {
          this.alert.submessage = `${this.alert.submessage}.\n Decline code: ${err.decline_code}`;
        }
      } finally {
        this.bottomRow = 'closeOnly';
      }
    }
  }
};